@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
@import-normalize;

.App {
  width: 100%;
  height:90% ;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Nunito", sans-serif;
  background-image: linear-gradient(90deg, #410076, #8820dd);
  text-align: center;
}

body {
  padding: 0;
  margin: 0;
}
img {
  border-radius: 15px;
  max-width: 500px;
  max-height: 400px;
}

#coverSettings {
  border-width: 3px;
  border-style: inset;
  border-color: #583673;
  border-radius: 15px;
  border: #900a46;
  margin-right: 950px;
  margin-top: 70px;
}

#itemsSettings {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-around;
  margin-left: 20cm;
}

.AppAlbum {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: "Nunito", sans-serif;
  background: rgb(91,22,176);
  background: linear-gradient(90deg, rgba(91,22,176,1) 22%, rgba(34,198,216,1) 66%);
  text-align: center;
  box-sizing: border-box;
}

.card .p-card-title{
  font-size: large;
}

.p-card .p-card-content{
  padding: 0;
}